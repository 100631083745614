import React from "react";
import PropTypes from "prop-types";

const Comparison = (props) => {
    let provider = props.data.provider,
        tierClass = `tier-${props.data.tier.toLowerCase()}`;

    return (
        <div className="col-6" key={"comparison-" + props.index}>
            <img data-src={provider.image} className="comparison-image lazyload" alt="provider-logo"/>
            <div className="comparison-tier-wrapper">
                <p className="comparison-tier-rating">{props.data.tier}</p>
                <p className="comparison-tier-text">{props.data.rating}</p>
            </div>
            <div className="comparison-rating-wrapper">
                <p className="comparison-rating-heading">
                    <svg className="comparison-rating-icon">
                        <use xlinkHref="/images/provider-info.svg#speed"/>
                    </svg>
                    <span className="comparison-rating-heading-text">SPEED — {provider.speed}ms</span>
                </p>
                <p className="comparison-rating-text">{props.data.speed}</p>
            </div>
            <div className="comparison-rating-wrapper">
                <p className="comparison-rating-heading">
                    <svg className="comparison-rating-icon">
                        <use xlinkHref="/images/provider-info.svg#uptime"/>
                    </svg>
                    <span className="comparison-rating-heading-text">UPTIME — {provider.live_uptime}%</span>
                </p>
                <p className="comparison-rating-text">{props.data.uptime}</p>
            </div>
            <div className="comparison-rating-wrapper margin-on-mobile">
                <p className="comparison-rating-heading">
                    <svg className="comparison-rating-icon">
                        <use xlinkHref="/images/provider-info.svg#price"/>
                    </svg>
                    <span className="comparison-rating-heading-text">PRICING — {provider.pricing}/10</span>
                </p>
                <p className="comparison-rating-text">{props.data.price}</p>
            </div>
            <div className={tierClass + " comparison-tier"}>
                <p className="tier-rating">{props.data.tier}</p>
                <p className="tier-text">TIER</p>
            </div>
        </div>
    );
};

Comparison.propTypes = {
    data: PropTypes.shape({
        price: PropTypes.string.isRequired,
        rating: PropTypes.string.isRequired,
        speed: PropTypes.string.isRequired,
        tier: PropTypes.string.isRequired,
        uptime: PropTypes.string.isRequired,
        provider: PropTypes.object.isRequired,
    }),
    index: PropTypes.number.isRequired
};

export default Comparison
