import React from "react";
import Layout from "../../layout/layout.component";
import {graphql} from "gatsby";
import PropTypes from "prop-types";
import SEO from "../../partials/seo/seo.component";
import PostHeading from "../../partials/post-heading/PostHeading.component";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import RelatedReads from "../../partials/related-reads/RelatedReads.component";
import CommentsLayout from "../../partials/comments/layout.component";
import Sidebar from "../../partials/sidebar/sidebar.component";
import TableOfContents from "../../partials/table-of-contents/table-of-contents.component";
import Comparisons from "../../partials/comparisons/Comparisons";

const ComparisonPost = (props) => {
    const comparison = props.data.wordpressPost,
        {schema} = props.pageContext,
        childPosts = comparison.child_posts ? JSON.parse(comparison.child_posts) : "",
        comments = props.data.allWordpressWpComments,
        comparisons = comparison.comparisons ? JSON.parse(comparison.comparisons) : "";

    return (
        <Layout whiteLayout={true}>
            <SEO data={schema}/>
            <Container>
                <div id="provider-card-wrapper">
                    <PostHeading title={comparison.title} date={comparison.small_date} text={comparison.information_under_title} className="post-heading-comparison"/>
                    {comparisons && <Comparisons data={comparisons}/> }
                </div>
                <Row className="justify-content-center">
                    <Sidebar>
                        <TableOfContents isTextAlignedRight={false} isDefaultLayout={true} elements={comparison.toc_elements}/>
                    </Sidebar>
                    <div id="review-post-content" className="col-12 col-md-8 col-lg-6 content-width" dangerouslySetInnerHTML={{__html: comparison.content}}></div>
                </Row>
                <Row className="justify-content-center">
                    <div className="col-12 col-md-8 col-lg-6 content-width">
                        {childPosts && Array.isArray(childPosts) && childPosts.length > 0 &&
                        <RelatedReads data={childPosts} />
                        }
                        <CommentsLayout comments={comments} postId={comparison.wordpress_id} isDefaultLayout={false}/>
                    </div>
                </Row>
            </Container>
        </Layout>
    );
};

ComparisonPost.propTypes = {
    pageContext: PropTypes.shape({
        schema: PropTypes.object.isRequired,
        id: PropTypes.string.isRequired,
    }),
    data: PropTypes.shape({
        wordpressPost: PropTypes.object.isRequired
    }),
};

export default ComparisonPost

export const pageQuery = graphql`
    query($id: String, $comment_parent_id: Int!) {
        wordpressPost(id: { eq: $id }) {
            title
            content
            front_template
            small_date
            information_under_title
            rating
            review_provider
            child_posts
            wordpress_id
            toc_elements
            comparisons
        }
      allWordpressWpComments(filter: {post: {eq: $comment_parent_id}, status: {eq: "approved"}, wordpress_parent: {eq: 0}}) {
            nodes {
              wordpress_id
              author_name
              author_email
              content
              link
              date_created
              children_count
              upvote_count
              wordpress_parent
              comment_like_ip_addresses
              children_comments {
                comment_ID
                comment_author
                comment_date
                comment_content
                comment_approved
                user_id
                upvote_count
                wordpress_id
                comment_like_ip_addresses
              }
            }
            totalCount
        }
    }
`;
