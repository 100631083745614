import React from "react";
import PropTypes from "prop-types";
import Row from "react-bootstrap/Row";
import Comparison from "./partials/Comparison";

const Comparisons = (props) => {
    return (
        <Row className="comparison-wrapper">
            {
                props.data &&
                    props.data.map((element, index) => {
                        return (
                            <Comparison data={element} index={index} key={"comparison" + index}/>
                        )
                    })
            }
        </Row>
    );
};

Comparisons.propTypes = {
    data: PropTypes.shape({
        price: PropTypes.string.isRequired,
        rating: PropTypes.string.isRequired,
        speed: PropTypes.string.isRequired,
        tier: PropTypes.string.isRequired,
        uptime: PropTypes.string.isRequired,
        provider: PropTypes.object.isRequired,
    }),
};

export default Comparisons
